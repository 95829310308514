
$textpaddingtopbot: 3.5rem;
$textpaddingsides: 7.5vw;
$textpadding: $textpaddingtopbot $textpaddingsides;


$textpaddingtopbot-sm: 2.5rem;
$textpaddingsides-sm: 2rem;
$textpadding-sm: $textpaddingtopbot-sm $textpaddingsides-sm;

section {
  z-index: 2;
  position: relative;
  background-position: center;
  background-size: cover;
  height: 100vh;
  &:nth-of-type(even) {
    margin-left: 50%;

    background-color: $white;
    padding: 0 $textpaddingsides;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    > div {
      max-width: 550px;
    }
  }
  &:nth-of-type(odd) {
    width: 100%;
  }
  &:first-of-type {
    position: fixed;
    top: 0;
    left:0;
    width: 50%;
    margin-left: 0;
    background-color: $bgred;
    z-index: 3;
  }
  &:nth-of-type(2) {
    width: 100%;
    position: fixed;
    top: 0;
    left:0;
    background-position: left;
    margin-left: 0;
    background-image: url("../img/ceiling_2000x1600px.jpg");
    z-index: 1;
  }
  &:nth-of-type(3) {
    //height: calc(100vh - #{$textpaddingtopbot});
  }
  h1 {
    margin-bottom: $textpaddingtopbot/3;
  }
  p {
    margin-bottom: $textpaddingtopbot/6;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

#taetigkeiten {
  > div > p {
    margin-bottom: $textpaddingtopbot;
  }
  li {
    display: flex;
    align-items: baseline;
    span {
      content:"-";
      height: 1px;
      border: 0;
      border-top: 1px solid #000;
      width: 60px;
      flex: 0 60px;
    }
    p {
      margin-bottom: $textpaddingtopbot/6;
      flex: 1;
      margin-left: $textpaddingtopbot/6;
    }
  }
}

#bgimg-tatig {
  background-image: url("../img/arrow-concrete_2000x1600px.jpg");
}

#bgimg-person {
  background-image: url("../img/paper_2000x1600px.jpg");
}

#bgimg-martin {
  background-image: url("../img/buero_2000x1600px.jpg");
}


@include bp(mobile){

  section {
    height: inherit;
    &:nth-of-type(even) {
      margin-left: 0;
      padding: $textpadding-sm;
    }
    &:nth-of-type(odd) {
      height: 50vh;
    }
    &:first-of-type {
      position: sticky;
      width: 100%;
      margin-left: 0;
      margin-top: 50vh;
      z-index: 2;
    }
    &:nth-of-type(2) {
      height:100vh;
    }
    &:nth-of-type(3) {
      display: none;
    }
    h1 {
      margin-bottom: $textpaddingtopbot-sm/2;
    }
    p {
      margin-bottom: $textpaddingtopbot-sm/6;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

}


@include bp(ipadV){
  section {
    height: inherit;
    &:nth-of-type(even) {
      margin-left: 0;
      padding: $textpadding-sm;
    }
    &:nth-of-type(odd) {
      height: 50vh;
    }
    &:first-of-type {
      position: sticky;
      width: 100%;
      margin-left: 0;
      margin-top: 50vh;
      z-index: 2;
    }
    &:nth-of-type(2) {
      height:100vh;
    }
    &:nth-of-type(3) {
      display: none;
    }
  }

}

@include bp(ipadH){

}

@include bp(desktop){

}
