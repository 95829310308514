
    @font-face{
        font-family:"Myriad W01 Regular";
        src:url("../assets/fonts/2bd106a4-3872-4193-8627-d7af96dd4f7e.eot?#iefix");
        src:url("../assets/fonts/2bd106a4-3872-4193-8627-d7af96dd4f7e.eot?#iefix") format("eot"),url("../assets/fonts/3b0f1c67-c2e4-4df6-976f-49d52e45aba1.woff2") format("woff2"),url("../assets/fonts/c5b1c170-d8f7-41f9-85c2-0ab670780c6b.woff") format("woff"),url("../assets/fonts/ba1ff8f6-0c27-4e7b-8be5-818b6c3dd801.ttf") format("truetype"),url("../assets/fonts/a379413e-a0b8-44dc-b250-1e000e4092a4.svg#a379413e-a0b8-44dc-b250-1e000e4092a4") format("svg");
    }

// EXAMPLE

// @font-face {
//     font-family: 'museo_slab500';
//     src: url('../assets/m/Museo_Slab_500_2-webfont.eot');
//     src: url('../assets/m/Museo_Slab_500_2-webfont.eot?#iefix') format('embedded-opentype'),
//          url('../assets/m/Museo_Slab_500_2-webfont.woff2') format('woff2'),
//          url('../assets/m/Museo_Slab_500_2-webfont.woff') format('woff'),
//          url('../assets/m/Museo_Slab_500_2-webfont.ttf') format('truetype'),
//          url('../assets/m/Museo_Slab_500_2-webfont.svg#museo_slab500') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

$fmr: 'Myriad W01 Regular', Helvetica, Arial, Sans-Serif;
