#impressum {
  height: inherit;
  p {
    color:$text;
    padding: 0;
  }
  svg {
    fill:$red;
  }
}
.impText {
  max-width: 65em;
  width: 70%;
  margin: 0 auto;
  padding-bottom: 2rem;

  h4 {
    margin: 3rem 0 .5rem 0;
  }
}

#impText-heading p.heading {
  margin:0 0 .75rem 0;
}

.impTextLogo {
  max-width: 50rem;
  margin: 0 auto;
  padding: 4rem 0 4rem;
}

.impTextLogo a {
  display: flex;
  justify-content: center;
}

.impContainer {
  margin: 0 2rem 0 2rem;
}

@include bp(mobile){
  .impText {
    width: 100%;
  }
}
@include bp(ipadV){
  .impText {
    max-width: 80%;
  }
}

@include bp(ipadH){
  .impText {
    max-width: 60%;
  }
}
