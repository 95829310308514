@mixin bp($point) {
  @if $point == mobile {
      @media (max-width: 600px)  { @content ; }
  }
  @else if $point == ipadV {
      @media (min-width: 601px) and (max-width:1023px) { @content ; }
  }
  @else if $point == ipadH {
      @media (min-width: 1024px) and (max-width:1279px) { @content ; }
  }
  @else if $point == desktop {
     @media (min-width:1280px) { @content ; }
  }
}


@mixin var($padding) {
  @if $padding == mobile {
    $textpaddingtopbot: 2.5rem;
    $textpaddingsides: 2rem;
    $textpadding: $textpaddingtopbot $textpaddingsides;
  }
  @else if $padding == ipadV {
      @media (min-width: 601px) and (max-width:1023px) { @content ; }
  }
}

// *** BACKGROUNDS **** \\

// $i_url:"../img";
// @mixin bg($id, $src, $pos) {
//     ##{$id}
//    {
//         @if($src!="") {background-image: url("#{$i_url}/pic-#{$src}.jpg");}
//         background-position:#{$pos};
//         background-size:cover;
//         background-repeat:no-repeat;
//     }
// }
// $backgrounds:(
//     "homepagepic" "home" "center",
//     "chisiamo" "chisiamo" "center",
//     "dormire" "dormire" "50% 94%",
//     "chisiamo_img-1" "agr-1" "center",
//     "chisiamo_img-2" "agr-2" "center",
//     "chisiamo_img-3" "agr-3" "center",
//     "chisiamo_img-4" "agr-4" "center",
//     "chisiamo_img-5" "storia" "center",
//     "pesco-img" "pesco" "center",
//     "albicocco-img" "albicocco" "center",
//     "fragola-img" "fragola" "center",
//     "ciliegio-img" "ciliegio" "center",
//     "melo-img" "melo" "center",
//     "pero-img" "pero" "center",
// );

// @each $id, $src, $pos in $backgrounds {
//     @include bg($id, $src, $pos);
// }

@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
     object-fit: $fit;
  @if $position {
    -o-object-position: $position;
       object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}
