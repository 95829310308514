

#logo {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15vh;
  svg {
    fill:$white;
  }
}

#menu--icon{
  position: absolute;
  top:0;
  left:0;
  margin: 1.75rem  2rem ;
  width: 2rem;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  svg {
    width: 100%;
    height: 100%;
    fill:$white;
  }
}

#header {
  color:$white;
  > p {
    text-align: center;
    margin: 1rem;
  }
}

#menu--itemsWrapper {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: inherit;
  background-color: $white;
  z-index: 4;
  transition: 1s transform;
  transform: translate3d(0,-100%,0);
  &.active {
    transform: translate3d(0,0,0);
  }
  ul {
    margin-left: 50%;
    margin-top: $textpaddingtopbot;
    margin-bottom: $textpaddingtopbot/4;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-around;
    li {
      letter-spacing: 0.15em;
    }
  }
}

@include bp(mobile){
  #logo {
    padding-top: 10vh;
    width: 80%;
    margin: 0 auto;
  }

  #menu--icon{
    margin: 1rem;
  }

  #menu--itemsWrapper {
    position: absolute;
    &.active {
      transform: translate3d(0,0,0);
    }
    ul {
      margin-left: 0;
      margin-top: $textpaddingtopbot-sm;
      margin-bottom: $textpaddingtopbot-sm;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      text-align: center;
      li a {
        padding: $textpaddingtopbot-sm/4 0;
        display: block;
      }
    }
  }
}

@include bp(ipadV){
  #logo {
    padding-top: 10vh;
    margin: 0 auto;
  }
}

@include bp(ipadH){

}

@include bp(desktop){

}
