footer {
  z-index: 4;
  position: relative;
  background-color: $white;
  display: flex;
  > div {
    width: 50%;
    padding: $textpadding;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  h2 {
    text-align: center;
  }
}

.contactInfo {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: $textpaddingtopbot;
  > p {
    width: 50%;

  }
}


@include bp(mobile){
  footer {
    display: block;
    > div {
      width: inherit;
      padding: $textpadding-sm;
      &:first-of-type {
        padding-bottom: 0;
      }
    }
    h2 {
      margin-bottom: $textpaddingtopbot-sm;
    }
  }

  .contactInfo {
    display: block;
    padding-top: $textpaddingtopbot-sm;
    > p {
      width: inherit;
    }
  }

}

@include bp(ipadV){


  .contactInfo {
    display: block;
    > p {
      width: inherit;
    }
  }

}
