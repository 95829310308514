$text: #000000;
$bgred: rgba(139,35,50,0.74);
$red: rgba(139,35,50,1);
$white: #ffffff;

@mixin color_hover($baseColor) {
    color: $baseColor;
    &:hover  {
    	color: darken($baseColor, 13%);
    }
}
